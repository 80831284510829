<template>
  <v-main>
    <nav-bar />
    <template slot:top>
      <v-subheader>
        <h1>Transactions</h1>
      </v-subheader>
      <v-toolbar flat>
        <v-text-field
          class="pr-2 flex-grow-1"
          v-model="query"
          label="Mot clé"
          @input="search"
        />
        <v-select
          v-model="searchField"
          :items="searchFields"
          class="pl-2 flex-grow-0"
          dense
          solo
          @change="search"
        />
      </v-toolbar>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="itemsLength"
      :options.sync="options"
      :must-sort="true"
      :footer-props="{ 'items-per-page-options': [50] }"
      dense
    >
      <template v-slot:item.user="{ item }">
        <v-btn small class="info" @click="editRenter(item.userUid)">
          {{ item.user }}
        </v-btn>
      </template>

      <!-- <template v-slot:item.property="{ item }">
        <v-btn v-if="item.property !== null" small class="info" @click="editProperty(item.propertyUid)">
          {{ item.property }}
        </v-btn>
      </template> -->

    </v-data-table>
  </v-main>
</template>

<script>
import axios from "axios";
import navBar from "../components/navBar.vue";
export default {
  components: {
    navBar,
  },
  data() {
    return {
      headers: [
        { text: "Id", value: "id", sortable: true },
        { text: "Compte", value: "paymentApp", sortable: true },
        { text: "Propriétaire", value: "user", sortable: true},
        // { text: "Logement", value: "property", sortable: true},
        { text: "Type", value: "type",sortable: true},
        { text: "Statut Swan", value: "swanStatus", sortable: true},
        { text: "Montant", value: "amount", sortable: true},
        { text: "Date de création", value: "creationDate", sortable: true},
        { text: "Date d'exécution", value: "swanDate", sortable: true },
        // { text: "Actions", value: "actions", sortable: false },
      ],
      searchFields: [
        {
          text: "Dans toutes les colonnes",
          value: "user,type,swanStatus",
        },
        { text: "Propriétaire", value: "user"},
        // { text: "Logement", value: "property"},
        { text: "Propriètaire", value: "user"},
        { text: "Type", value: "type"},
        { text: "Statut Swan", value: "swanStatus"},
      ],
      searchField: "user,type,swanStatus",
      items: [],
      itemsLength: 0,
      options: {
        sortBy: ["id"],
        sortDesc: [true],
      },
      query: "",
    };
  },

  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },

  methods: {
    getItems() {
      axios
        .get(process.env.VUE_APP_APIURL + "/paymentAppsTransactions", {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            offset: (this.options.page - 1) * this.options.itemsPerPage,
            limit: this.options.itemsPerPage,
            sort:
              this.options.sortBy[0] +
              " " +
              (this.options.sortDesc[0] ? "DESC" : "ASC"),
            searchQuery: this.query !== "" ? this.query : undefined,
            searchFields: this.searchField,
          },
        })
        .then((response) => {
          this.items = response.data.map((item) =>
            Object.assign(
              item,
              { creationDate: new Date(item.creationDate).toLocaleString() },
              { swanDate: new Date(item.swanDate).toLocaleString()},
              {
                type: {
                  1: item.swanSide === "Debit" ? "1 (Annulation paiement reçu)" : "1 (Paiement reçu)",
                  2: item.swanSide === "Credit" ? "2 (Remboursement dépôt de garantie)" : "2 (dépôt de garantie)",
                  3: item.swanSide === "Credit" ? "3 (Annulation virement externe)" : "3 (virement externe)",
                }[item.type]
              },
              { amount: item.swanSide === "Credit" ? `+ ${item.amount}` : `- ${item.amount}`},
            )
          );
        });
      axios
        .get(process.env.VUE_APP_APIURL + "/paymentAppsTransactions", {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            count: 1,
            searchQuery: this.query !== "" ? this.query : undefined,
            searchFields: this.searchField,
          },
        })
        .then((response) => {
          this.itemsLength = eval(response.data[0].count);
        });
    },

    search() {
      this.options.page = 1;
      this.getItems();
    },

    editRenter(uid) {
      open(
        this.$router.resolve({ name: "user", params: { userUid: uid } }).href,
        "_blank"
      );
    },

    // editProperty(uid) {
    //   open(
    //     this.$router.resolve({ name: "property", params: { propertyUid: uid } })
    //       .href,
    //     "_blank"
    //   );
    // },
  },
};
</script>